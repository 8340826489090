import React from 'react'
import '../Styles/Home.css'
import { FaInstagram,FaDiscord } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";

const Home = () => {
  return (
    <div className='home'>
        <h1 className='bungee-regular'>
            FL3UR_1NESTIMABLE
        </h1>
        <div className='home-sub'>
          <h2 className=''>
              Welcome
          </h2>
          <img src='fraise.png' alt='strawberry' />
        </div>
        <div className='home-pres'>
          <p className='titillium-web-regular'>
            Hi ! I'm Antoine, also known as Fl3ur_1nestimable. <br />
            Welcome to my website. Here you can discover my music, or play around with the piano (also works with MIDI devices!)<br /> <br />
            Don't hesitate to contact me anywhere ! 🌸
          </p>
        </div>
        <div className='home-contact'>
          <a href='https://www.instagram.com/fleur_inestimable/' target='_blank' rel='noreferrer'>
            <FaInstagram />
          </a>
          <a href='https://discordapp.com/users/412538831326150658' target='_blank' rel='noreferrer'>
            <FaDiscord />
          </a>
          <a href='mailto:yebouetephraim@gmail.com'>
            <MdMailOutline />
          </a>
        </div>
    </div>
  )
}

export default Home
