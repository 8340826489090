import React, { useEffect, useState } from "react";
import "./App.css";
import Piano from "./Components/Piano";
import {Piano as ThePiano} from "@tonejs/piano"
import Cards from "./Components/Cards";

const MyPiano = new ThePiano({velocities: 5, pedal: true});
MyPiano.toDestination();

const App = () => {
    const octaveWidth = 280;
    const maxOctaves = Math.floor(window.innerWidth / octaveWidth + 1);
    const [nbOfOctaves, setNbOfOctaves] = useState(maxOctaves);
    const [midiAccess, setMidiAccess] = useState(null);
    const noteBemols = {
        "C#" : "Db",
        "D#" : "Eb",
        "F#" : "Gb",
        "G#" : "Ab",
        "A#" : "Bb"
    };
    const naturalNotes = [ "C", "D", "E", "F", "G", "A", "B" ];
    const notes = [ "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B" ];
    const [pianoLoaded, setPianoLoaded] = useState(false);
    const [readyToPlay, setReadyToPlay] = useState(false);

    useEffect(() => {
        MyPiano.load().then(() => {
            console.log("Piano loaded");
            setPianoLoaded(true);
        });

        // Request MIDI access
        if (navigator.requestMIDIAccess) {
            navigator.requestMIDIAccess()
                .then(onMIDISuccess, onMIDIFailure);
        } else {
            console.error("Web MIDI API is not supported in this browser.");
        }

        // Adjust number of octaves when resizing the window
        const resizeHandler = () => {
            setNbOfOctaves(Math.floor(window.innerWidth / octaveWidth + 1));
        };
        window.addEventListener('resize', resizeHandler);

        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    const midiNoteToPianoNote = (midiNote) => {
        const octave = Math.floor(midiNote / 12) - 1;
        const note = notes[midiNote % 12];
        return note + octave;
    };

    const onNotePlay = (note, velocity,isdisabled) => {
        const octave = note[note.length - 1];
        note = note.slice(0, -1);
        if (noteBemols[note]) {
            note = noteBemols[note];
        }
        note += octave;
        velocity = velocity/300;
        MyPiano.keyDown({note: note, velocity: velocity});
    };
    
    const onNoteStop = (note) => {
        const octave = note[note.length - 1];
        note = note.slice(0, -1);
        if (noteBemols[note]) {
            note = noteBemols[note];
        }
        note += octave;
        MyPiano.keyUp({note: note});
    };
    
    

    const onMIDISuccess = (midiAccess) => {
        setMidiAccess(midiAccess);
        const inputs = midiAccess.inputs;
        console.log(inputs);

        inputs.forEach((input) => {
            input.onmidimessage = handleMIDIMessage;
        });
    };

    const onMIDIFailure = () => {
        console.error("Could not access your MIDI devices.");
    };

    const handleMIDIMessage = (message) => {
        //if(!readyToPlay) return;
        const [command, note, velocity] = message.data;
        const key = midiNoteToPianoNote(note);
        const keybtn = document.getElementById(key);
        if (command === 144 && velocity > 0) { // Note on
            keybtn.classList.add("active");
            onNotePlay(key, velocity);
        }
        if (command === 128 || (command === 144 && velocity === 0)) { // Note off
            keybtn.classList.remove("active");
            onNoteStop(key);
        }
        if((command === 176 || command === 177 || command === 178) && note === 64 && velocity === 0){
            MyPiano.pedalDown();
        }
        if((command === 176 || command === 177 || command === 178) && note === 64 && velocity === 127){
            MyPiano.pedalUp();
        }
    };


    return (
        <div className="piano-scroll">
            <Cards />
            <Piano nbOfOctaves={9} onNotePlay={onNotePlay} onNoteUp={onNoteStop} />
            {!readyToPlay &&
              <div className="piano-load">
                <h1>
                  {pianoLoaded ? 
                    <button className="titillium-web-bold" onClick={() => setReadyToPlay(true)}>Open the piano ! 🎹</button>
                  : <p className="titillium-web-regular">Loading piano...</p> }
                </h1>
              </div>
            }
        </div>
    );
};

export default App;
