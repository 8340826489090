import React from "react";
import "../Styles/Piano.css";

const Piano = ({ nbOfOctaves, onNotePlay, onNoteUp }) => {

    const notes = [ "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B" ];
    const isSharp = (note) => {
        return note.includes("#");
    };

    const handleMouseDown = (note) => {
        onNotePlay(note, 70, false);
    };

    const handleMouseUp = (note) => {
        onNoteUp(note);
    };

    const handleTouchStart = (note,e) => {
        e.preventDefault();
        e.stopPropagation();
        e.cancelBubble = true;
        e.returnValue = false;
        onNotePlay(note, 70, false);
    }


    const renderOctave = (octNb) => {
        return notes.map((note, index) => {
            const keyClass = `key ${isSharp(note) ? "sharp" : "natural"} ${note.replace("#", "-sharp")}`;
            const keyId = note + octNb;
            return (
                <div key={index} className={keyClass} id={keyId} onTouchStart={(e)=>handleTouchStart(keyId,e)} onMouseDown={(e) => handleMouseDown(keyId,e)} onTouchCancel={()=>handleMouseUp(keyId)} onMouseUp={() => handleMouseUp(keyId)}>
                    {note}
                </div>
            );
        });
    };

    const renderPiano = () => {
        const octaves = [];
        for (let i = 0; i < nbOfOctaves; i++) {
            octaves.push(
                <div key={i} className="octave">
                    {renderOctave(i)}
                </div>
            );
        }
        return octaves;
    };

    return (
        <div className="piano">
                {renderPiano()}
        </div>
    );
}

export default Piano;
