import React from 'react'
import '../Styles/Fleur.css'

const Fleur = () => {
  return (
    <div className='fleur'>
        <h1>
            My Music
        </h1>
        <div className='fleur-pres'>
          <p className='titillium-web-regular'>
             I can't live without music. I have been playing the piano for 13 years now. <br />
             I love Jazz and electronic music. I'm also a bit of a producer. <br />
          </p>
        </div>
        <div className='fleur-music'>
            <div className="spotify">
              <iframe title='My Spotify' style={{borderRadius:"12px"}} src="https://open.spotify.com/embed/artist/3ruacNrPgm29H566SxwbhP?utm_source=generator&theme=0" width="100%" height="100%" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
            <p className='titillium-web-regular'>
              You can also visit my <a className='titillium-web-bold' href='https://youtube.com/@fl3ur_1nestimable?si=LLNEIazspsRYphmU' target='_blank' rel='noreferrer'>Youtube</a> channel for more!
            </p>
        </div>
    </div>
  )
}

export default Fleur
