import React from 'react'
import '../Styles/Cards.css'
import Card from './Card'
import Home from './Home'
//import Ayeb from './Ayeb'
import Fleur from './Fleur'

const Cards = () => {
  return (
    <div className='cards'>
        <Card>
            <Home />
        </Card>
        <Card>
            <Fleur />
        </Card>

    </div>
  )
}

export default Cards
